import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import device from '../helpers/breakpoints';
import Layout from '../components/layout';
import Nav from '../components/nav';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Anchor from '../helpers/anchor';

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        title
        description
      }
    }
    image: file(relativePath: { eq: "laptops-xprtz1.jpg" }) {
      publicURL
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jasper: file(relativePath: { eq: "dit_is_jasper.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dennis: file(relativePath: { eq: "en_ik_ben_dennis.png" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo: file(relativePath: { eq: "symboltext-xprtz-white.svg" }) {
      publicURL
    }
    arrowdown: file(relativePath: { eq: "arrowdown.svg" }) {
      publicURL
    }
  }
`;

const BodyContainer = styled.div`
  max-width: 728px;
  padding: 0 1rem;
  margin: 8rem auto 4rem;

  @media ${device.tablet} {
    padding: 0 2rem;
    max-width: 960px;
  }

  @media ${device.laptop} {
    padding: 0 8rem;
    max-width: 1280px;
  }

  @media ${device.laptopL} {
    padding: 0 8rem;
    max-width: 1440px;
  }

  h1 {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.022em;
  }

  h2 {
    font-size: 26px;
    line-height: 1.18;
    letter-spacing: -0.022em;
    margin-top: 1.72em;

    + p {
      margin-top: 0.46em;
    }
  }

  p {
    font-size: 21px;
    line-height: 1.58;
    letter-spacing: -0.004em;
    margin-top: 2em;
  }
`;

const Emphasis = styled.span`
  font-weight: bold;
  margin-bottom: 0.2em;
  display: inline-block;
`;

const WerkenBijPage = ({ data }) => {
  return (
    <Layout
      metadata={{ ...data.site.siteMetadata, image: data.image.publicURL }}
    >
      <Nav Root="/" Fixed="true" />
      <BodyContainer>
        <Anchor id="banner-anchor"></Anchor>
        <h1>Werken bij XPRTZ</h1>
        <p>
          <Emphasis>
            Complexe backend puzzels bij een grote enterprise of een toffe app
            voor een start-up. Bij XPRTZ kiezen wij samen jouw ideale opdracht.
            Natuurlijk altijd met een .NET (Core) insteek, want daar ligt onze
            focus en jouw expertise.
          </Emphasis>
          In ons clubhuis laat je jouw pool-, race- en codingskills zien tijdens
          de diverse code camps, meetups, kennissessies en labdagen of praat je
          gewoon lekker bij met gelijkgestemde .NET experts die je écht kent. Je
          draait je hand bijvoorbeeld niet om voor het inrichten van een CI/CD
          pipeline met YAML in Azure DevOps, heeft GraphQL geen geheimen meer
          voor je, is jouw GitHub contribution overzicht meer groen dan grijs en
          vind je het leuk om jouw hobby projecten te deployen met Kubernetes in
          verschillende Docker containers. Bij XPRTZ gaat het bijhouden van jouw
          eigen GitHub commits niet ten koste van je klantopdracht. Kennis
          opdoen, delen en bijblijven is namelijk net zo belangrijk.
        </p>
        <p>
          Kortom; jij bent een kick-ass developer met passie voor het vak. Werkt
          liever in een team dan alleen en blijft niet ‘hangen’ in een opdracht
          maar neemt zo nu en dan afstand om kennis te ontwikkelen en eens goed
          artikel te publiceren, webcast op te nemen of een talk te houden op
          een event, meetup of kennissessie.
        </p>

        <h2>Wat krijg je?</h2>
        <ul>
          <li>Fulltime vast contract (40 uur).</li>
          <li>Een laptop naar keuze.</li>
          <li>26 vakantiedagen.</li>
          <li>Riante winstdeling aan het einde van het jaar.</li>
          <li>Stabiele organisatie met lange termijn focus.</li>
          <li>Toonaangevende opdrachtgevers in heel Nederland.</li>
        </ul>
        <h2>Wat vragen wij?</h2>
        <ul>
          <li>Minimaal HBO werk- en denkniveau.</li>
          <li>Je hebt minimaal 5 jaar werkervaring met .NET.</li>
          <li>
            Je bent op de hoogte van de laatste trends en ontwikkelingen op het
            gebied van .NET.
          </li>
          <li>
            Ondernemende mindset: je denkt niet in problemen, maar in
            oplossingen.
          </li>
          <li>
            Naast hard werken vind je het ook belangrijk om plezier te maken met
            je collega's.
          </li>
        </ul>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            min-height: 250px;
            margin: 1rem auto;
          `}
        >
          <img
            src={data.jasper.sharp.fluid.src}
            alt="Dit is Jasper"
            height="250px"
          />
          <img
            src={data.dennis.sharp.fluid.src}
            alt="En ik ben Dennis"
            height="250px"
            css={css`
              margin: 0 0 0 3rem;

              @media ${device.mobileL} {
                margin: 0;
              }
            `}
          />
        </div>
      </BodyContainer>
      <Contact />
      <Footer />
    </Layout>
  );
};

export default WerkenBijPage;
